'use strict';window.site=window.site||{};/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */site.BlogFilter=function BlogFilter(){'use strict';/**
   * jQuery elements
   * @private
   *//**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * Initializes the class.
   * @public
   */var init=function init(){// Abort if already initialized
if(inited){return false;}inited=true;var $tabs=[].slice.call(document.querySelectorAll('.news .news-links.js-click .news-link'));var $containers=[].slice.call(document.querySelectorAll('.news .blog-listing.home'));var $btns=[].slice.call(document.querySelectorAll('.news .news-button.home'));var $currentURL=window.location.href;if($tabs.length&&$containers.length){var $nextURL=$currentURL+'/publications?type=events';var articlesCount=$containers[1].getAttribute('data-count');if(articlesCount>3){$btns[0].style.display="inline-block";$btns[0].href=$nextURL;}else{$btns[0].style.display="none";}$tabs.forEach(function($tab){$tab.addEventListener('click',function(){if(!$tab.classList.contains('is-active')){var type=$tab.getAttribute('data-type');$tabs.forEach(function($tabLoop){if($tabLoop.classList.contains('is-active')){$tabLoop.classList.remove('is-active');}else if($tabLoop.getAttribute('data-type')==type){$tabLoop.classList.add('is-active');}});$containers.forEach(function($container){if($container.classList.contains('is-active')){$container.classList.remove('is-active');}else if($container.getAttribute('data-type')==type){$container.classList.add('is-active');var _$nextURL=$currentURL+'/publications?type='+type;var _articlesCount=$container.getAttribute('data-count');if(_articlesCount>3){$btns[0].style.display="inline-block";$btns[0].href=_$nextURL;}else{$btns[0].style.display="none";}}});}});});}return true;};// Expose public methods & properties
return{init:init};}();