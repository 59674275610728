"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $trigger = document.querySelector('.mobile-menu-trigger');
    var $triggerClose = document.querySelector('.navigation-close');
    if ($trigger) {
      $trigger.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');
        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });
    }
    if ($triggerClose) {
      $triggerClose.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');
        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });
    }
    var $exception = document.querySelector('.navigation-link a.scroll');
    if ($exception) {
      $exception.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');
        $main.classList.remove('active');
        $menu.classList.remove('is-active');
        return true;
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();