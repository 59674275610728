'use strict';window.site=window.site||{};/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */site.Map=function Map(){'use strict';/**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * jQuery elements.
   *//**
   * Initializes the class.
   * @public
   */var init=function init(){// Abort if already initialized
if(inited){return false;}inited=true;// initMaps();
// Return success
return true;};/**
   * Initialize Google Maps instances.
   * @public
   */var initMap=function initMap(){// The location of Uluru
var montreal={lat:45.526920,lng:-73.575029};// var longueuil = {lat: 45.532229, lng: -73.484889};
var center={lat:45.519527,lng:-73.534374};// The map, centered at Uluru
var map=new google.maps.Map(document.querySelector('[data-map]'),{zoom:12,center:center,styles:[{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]});// The marker, positioned at Uluru
var marker=new google.maps.Marker({position:montreal,map:map,icon:'/img/site/icon-marker.png'});// var marker = new google.maps.Marker({position: longueuil, map: map, icon: '/img/site/icon-marker.png'});
};// Expose public methods & properties
return{init:init,initMap:initMap};}();