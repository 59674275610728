'use strict';window.site=window.site||{};/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */site.OffersGalleries=function OffersGalleries(){'use strict';/**
   * jQuery elements
   * @private
   *//**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * Initializes the class.
   * @public
   */var init=function init(){// Abort if already initialized
if(inited){return false;}inited=true;var $offers=[].slice.call(document.querySelectorAll('.offers.is-active .offer'));var $studyAreas=[].slice.call(document.querySelectorAll('.study-areas .study-area'));var $spaces=[].slice.call(document.querySelectorAll('.results-listing .result-item'));if($offers){$offers.forEach(function($offer){var $slider=$offer.querySelector('.offer-gallery');var $images=[].slice.call($slider.querySelectorAll('img'));if($images){setTimeout(function(){var flkty=new Flickity($slider,{// options
cellAlign:'left',contain:true,pageDots:false,imagesLoaded:true,wrapAround:true,lazyLoad:true});},500);}});}if($spaces){$spaces.forEach(function($space){var $slider=$space.querySelector('.result-item-gallery');var $images=[].slice.call($slider.querySelectorAll('img'));if($images){setTimeout(function(){var flkty=new Flickity($slider,{// options
cellAlign:'left',contain:true,pageDots:false,imagesLoaded:true,wrapAround:true,lazyLoad:true});},500);}});}if($studyAreas){$studyAreas.forEach(function($area){var $slider=$area.querySelector('.study-area-gallery');var $images=[].slice.call($slider.querySelectorAll('img'));if($images){setTimeout(function(){var flkty=new Flickity($slider,{// options
cellAlign:'left',contain:true,pageDots:false,imagesLoaded:true,wrapAround:true,lazyLoad:true});},500);}});}return true;};// Expose public methods & properties
return{init:init};}();