'use strict';window.site=window.site||{};/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */site.OffersSpaceGalleries=function OffersSpaceGalleries(){'use strict';/**
   * jQuery elements
   * @private
   *//**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * Initializes the class.
   * @public
   */var init=function init(){// Abort if already initialized
if(inited){return false;}inited=true;var $gallery=document.querySelector('.space-offers .offers-gallery.is-active');if($gallery){var $images=[].slice.call($gallery.querySelectorAll('img'));if($images){setTimeout(function(){var flktySpace=new Flickity($gallery,{// options
cellAlign:'left',contain:true,pageDots:false,imagesLoaded:true,wrapAround:true,lazyLoad:true,adaptiveHeight:true});},500);}}var $descriptions=document.querySelectorAll('.offers-description');if($descriptions){$descriptions.forEach(function($description){var $titleDescription=$description.querySelector('.offers-desc-title');$titleDescription.addEventListener('click',function(){if(!$description.classList.contains('is-active')){var offerId=$description.getAttribute('data-offer');// Remove active description
$descriptions.forEach(function($loopDescription){$loopDescription.classList.remove('is-active');});// Activate clicked description
$description.classList.add('is-active');var currentGallery=document.querySelector('.space-offers .offers-gallery.is-active');currentGallery.classList.remove('is-active');// Handle galleries
var $descriptionGalleries=[].slice.call(document.querySelectorAll('.space-offers .offers-gallery'));$descriptionGalleries.forEach(function($descriptionGallery){if($descriptionGallery.getAttribute('data-offer')===offerId){$descriptionGallery.classList.add('is-active');window.scrollTo({top:$descriptionGallery.offsetTop,behavior:"smooth"});// Activate Flickity
var _$images=[].slice.call($descriptionGallery.querySelectorAll('img'));if(_$images){var flktyDescription=new Flickity($descriptionGallery,{// options
cellAlign:'left',contain:true,pageDots:false,imagesLoaded:true,wrapAround:true,lazyLoad:true,adaptiveHeight:true});}}});}});});}return true;};// Expose public methods & properties
return{init:init};}();