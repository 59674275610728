"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.HeroSwitch = function HeroSwitch() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $tailDatetimeFields = [].slice.call(document.querySelectorAll('.tail-datetime-field'));
  var $pristineForms = [].slice.call(document.querySelectorAll('[data-pristine-validation]'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    document.addEventListener('DOMContentLoaded', function () {
      var $now = new Date();
      var $weekLimit = 4;
      var $tail = tail.DateTime($tailDatetimeFields, {
        locale: window.site.App.config('locale'),
        animate: true,
        timeHours: false,
        timeMinutes: false,
        timeSeconds: false,
        timeFormat: '',
        today: true,
        dateStart: new Date(),
        dateEnd: $now.setDate($now.getDate() + $weekLimit * 7),
        dateFormat: window.site.App.config('locale') === 'fr' ? 'dd F YYYY' : 'F dd, YYYY'
      });
      $tailDatetimeFields.forEach(function ($field) {
        $field.addEventListener('change', function () {
          var currentDate = $tail.fetchDate();
          $field.nextElementSibling.value = currentDate.getTime();
        }, true);
      });
    });
    if ($pristineForms) {
      var defaultConfig = {
        classTo: 'fields-group',
        errorTextParent: 'fields-group',
        errorTextTag: 'div'
      };
      $pristineForms.forEach(function ($form) {
        var pristine = new Pristine($form, defaultConfig);
        $form.addEventListener('submit', function (e) {
          e.preventDefault();
          var valid = pristine.validate();
          if (valid) {
            var formData = new FormData($form);
            if ($form.classList.contains('booking-form')) {
              $form.classList.add('is-loading');
              window.axios.post('/actions/site-module/default/booking-study-area', formData).then(function (response) {
                $form.classList.remove('is-loading');
                if (response.data.status === 'error') {
                  $form.classList.add('has-failed');
                } else {
                  // Redirect to the confirmation page
                  window.location.href = $form.dataset.redirect;
                }
              }).catch(function (error) {
                console.log(error);
              });
            } else {
              formData.delete('CRAFT_CSRF_TOKEN');
              var asString = new URLSearchParams(formData).toString();
              window.location.href = "".concat($form.action, "?").concat(asString);
            }
          }
        }, false);
      });
    }
    var $switch = document.querySelector('.hero-radios');
    if ($switch) {
      var $radios = [].slice.call($switch.querySelectorAll('input[type=radio]'));
      var $homeSection = document.querySelector('#hero-home');
      $homeSection.style.backgroundImage = "url('" + $homeSection.dataset.backgroundMontreal + "')";
      if ($radios) {
        $radios.forEach(function ($radio) {
          $radio.addEventListener('change', function (e) {
            var value = $radio.value;
            var $titles = [].slice.call(document.querySelectorAll('.hero-info'));
            var $offers = [].slice.call(document.querySelectorAll('.offers'));
            var $videos = [].slice.call(document.querySelectorAll('.hero-cta'));
            $titles.forEach(function ($title) {
              $title.classList.remove('is-active');
            });
            $videos.forEach(function ($video) {
              $video.classList.toggle('is-active');
            });
            var $selectedTitle = document.querySelector('.hero-info[data-name=' + value + ']');
            $selectedTitle.classList.add('is-active');
            $offers.forEach(function ($offer) {
              $offer.classList.remove('is-active');
            });
            var $selectedOffer = document.querySelector('.offers[data-office=' + value + ']');
            $selectedOffer.classList.add('is-active');
            var $homeSection = document.querySelector('#hero-home');
            if ($homeSection) {
              var $url = '';
              if (value == 'montreal') {
                $url = $homeSection.dataset.backgroundMontreal;
              } else {
                $url = $homeSection.dataset.backgroundLongueuil;
              }
              $homeSection.style.backgroundImage = "url('" + $url + "')";
            }
            var $offersGalleries = [].slice.call(document.querySelectorAll('.offers.is-active .offers-container .offer'));
            if ($offersGalleries) {
              $offersGalleries.forEach(function ($offer) {
                var $slider = $offer.querySelector('.offer-gallery');
                var $images = [].slice.call($slider.querySelectorAll('img'));
                if ($images) {
                  var flkty = new Flickity($slider, {
                    // options
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    imagesLoaded: true,
                    wrapAround: true,
                    lazyLoad: true
                  });
                }
              });
            }
          });
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();