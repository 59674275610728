'use strict';window.site=window.site||{};/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */site.YoutubeVideo=function YoutubeVideo(){'use strict';/**
   * jQuery elements
   * @private
   *//**
   * Has the class been initialized?
   * @private
   */var inited=false;/**
   * Initializes the class.
   * @public
   */var init=function init(){// Abort if already initialized
if(inited){return false;}inited=true;function hasClass(element,cls){return(' '+element.className+' ').indexOf(' '+cls+' ')>-1;}var video=document.querySelector('#playVideo');var videoMtl=document.querySelectorAll('[data-video-montreal]');var videoRs=document.querySelectorAll('[data-video-rivesud]');if(video){video.onclick=function(){if(hasClass(video,'is-active')===true){basicLightbox.create('\n          <iframe width="560" height="315" src="'+videoMtl[0].dataset.videoMontreal+'" frameborder="0" allowfullscreen></iframe>\n          ').show();}else{basicLightbox.create('\n          <iframe width="560" height="315" src="'+videoRs[0].dataset.videoRivesud+'" frameborder="0" allowfullscreen></iframe>\n          ').show();};};}return true;};// Expose public methods & properties
return{init:init};}();